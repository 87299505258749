import React from 'react';

class Name extends React.Component {
    render() { return(
        <div class="name" >
            <a href="index.html">
            <h2>Christopher James Murphy</h2>
            </a>
        </div>)
    }
}
export default Name;