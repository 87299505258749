import React from 'react';
class Shop extends React.Component {
    render() { 
        let text = <a href="https://www.artfinder.com/cjmurphyart" title="Follow Christopher James Murphy on Artfinder"><img src="https://gallery.mailchimp.com/cab05aa04fc6aa9576d99dc35/images/2fdc6561-a335-4f4b-a949-1adc573fcf9f.jpg" style="max-width: 100%;" alt="Follow Christopher James Murphy on Artfinder" /></a>;
    
        return( <div>{text}</div>)
    }
}

export default Shop;
