import React from 'react';
export default class Bio extends React.Component {
    render() {
        let text = <div>
            <p>
                Christopher Murphy is a painter from North Wales based in London. Working in oils and watercolours, his main subjects are the male nude and portraits.
                His work plays with narcissism and homoeroticism, affection and sensuality.
            </p>
            <h3>Exhibitions</h3>
            <p><b>Fifty Two</b>, <a href="https://prideinlondon.org/fifty-two/christopher-james-murphy">prideinlondon.org</a>, Aug 20</p>
            <p><b>Raw Perspectives</b>, Espacio Gallery, Dec 19</p>
            <h3>Publications</h3>
            <p>Illustrations for <b><i>Georges Guyon architecte de l'élégance et bâtisseur au grand coeur</i>, Laurence Dujancourt, 2019</b></p>
        </div>;

        return (
            <div class="bio">
                {text}
                <img key="bioImg" src={require('./images/bio.jpg')} alt="info" class="painting bioImg" />
            </div>)
    }
}