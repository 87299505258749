import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

class Print extends React.Component {
    render() {
      return (
        <div className="print">
          <a href={this.props.printImage}>
            <img src={this.props.printImage} class="print-image" />
          </a>
          <h2>{this.props.print.name}</h2>
          <p><FontAwesomeIcon icon={faCoffee} />{this.props.print.desc}</p>
          <h3>{this.props.print.price}</h3>
          <p><a href={this.props.print.link}>{this.props.print.text}</a></p>
          <p>{this.props.print.size}</p>
          <p>{this.props.print.edition}</p>
        </div>
      );
  
    }
  }
  
export default Print;
