import React, { Component } from 'react';

export default class ContentButton extends Component {

    render() {
        const buttonType = this.props.type;
        const buttonLabel = this.props.label;
        return (
            <div class="button" id="regularButton" onClick={() => this.props.callback(buttonLabel)}>
                <div id="slide"></div>
                <a>{buttonLabel}</a>
            </div>
        )
    }
}
