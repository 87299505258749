import Name from './name.js'
import React from 'react';
import ReactDom from 'react-dom';
import './index.css';
import './buttons.css';
import StatefulContentWindow from './StatefulContentWindow.js';

class Demo extends React.Component {
  render() {
  return (
    <div style={{ position: 'relative', height: 300 }}>
      <div style={{float: 'right', marginLeft: '30px', marginRight: '30px'}}>
        <Name />
      </div> 
      <StatefulContentWindow />
     </div>);
  } 
}
ReactDom.render(<Demo />, document.getElementById('root'));