import React, { Component } from 'react'
import Tween from 'rc-tween-one';
import FunCanvas from './FunCanvas';

export default class FunContent extends Component {
    render() {
        return (
        <div style={{ position: "fixed", zIndex: -1 }}>
          <Tween animation={{
            bezier: {
              type: 'thru', autoRotate: true,
              vars: [{ x: 200, y: 200 }, { x: 400, y: 0 }, { x: 600, y: 200 }, { x: 800, y: 0 }],
            },
            duration: 5000,
            repeat: -1
          }}
            style={{ width: 100 }}
          >
            <div>⬤</div>
          </Tween>
          <FunCanvas />
        </div>
        )
    }
}
