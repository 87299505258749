import React from 'react';
import ReactDOM from 'react-dom';

const colours = ["blue", "yellow", "red", "orange", "green", "black"]
function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }
  function getCoord(coord) {
    return coord + getRndInteger(0, 50);
  }
  function getNextCoord(previous) {
      
  }
export default class FunCanvas extends React.Component {
    constructor() {
        super();
    this.xSize = 900;
    this.ySize = 300;
    }
    componentDidMount() {
        this.updateCanvas();
    }
    updateCanvas() {
        const ctx = this.refs.canvas.getContext('2d');
        //ctx.fillRect(10,30, 100, 100);
        for(var i =0; i < 10; i++) {
        ctx.beginPath();
        ctx.fillStyle = colours[getRndInteger(0,colours.length)];
        //ctx.arc(getRndInteger(0,300), getRndInteger(0,300), getRndInteger(0,30), 0, Math.PI*2, true);
        var x = getRndInteger(50, this.xSize - 50);
        var y = getRndInteger(50, this.ySize - 50);
        
        ctx.moveTo(x, y);
        ctx.lineTo(getCoord(x),getCoord(y));
        ctx.lineTo(getCoord(x),getCoord(y));
        ctx.lineTo(getCoord(x),getCoord(y));
        ctx.closePath();
        ctx.fill();
        }
    }
    render() {
        return (
            <canvas ref="canvas" width={this.xSize} height={this.ySize} padding={50}/>
        );
    }
}
//ReactDOM.render(<CanvasComponent/>, document.getElementById('root'));
