import React from 'react';
import '@google/model-viewer/dist/model-viewer';

export default class ThreeD extends React.Component {
    render() { 
        return( 
        <div class="threeD">
            <model-viewer class="model" src={require('./images/brush.glb')} alt="A paintbrush" auto-rotate camera-controls></model-viewer>
            <model-viewer class="model" src={require('./images/generativeintro.glb')} alt="A generated plane" auto-rotate camera-controls></model-viewer>
        </div>)
    }
}