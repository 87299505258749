import React from 'react';

import Print from './Print.js';

import snowdon from './images/snowdon.jpg';
import america from './images/america.jpg';
import coffeework from './images/coffeework.jpg';


import autumn from './images/autumn.JPG';
import lgbt_10 from './images/lgbt_10.JPG';
import lgbt_pink from './images/lgbt_pink.JPG';
import lgbt_orange from './images/lgbt_orange.JPG';

import './Charity.css';

const divStyle = {
  backgroundImage: `url(${autumn})`,
  padding: `50px`,
  color: 'white'
};

const lgbtPrint = {
  name: 'Still Together',
  desc: `Though we\'re apart we can still be proud of who we are. This design is inspired by the colours of the pride flag and the help that the LGBT Foundation offer, listening and offering advice. 
  In gratitude for offering me support when I lived in Manchester.
  Each print is uniquely coloured and is therefore one-of-a-kind.
  `,
  text: `This print is sold in aid of the LGBT Foundation.`,
  link: "https://lgbt.foundation/",
  price: "30GBP",
  edition: "20/20",
  size: "10cm x 20cm"
};

const nhsPrint = {
  name: 'Support',
  desc: `My mother has been in nursing for my entire life and has offered a voice of reason and encouragement when I've needed it during this current pandemic.
  Since she'd been through it all before, I managed to worry a little less about her and myself - but we need to support frontline workers like her much more.
  As of this year my sister has also been employed by the NHS, so this print is inspired by family.
  `,  
  text: `This print is sold in aid of the NHS Charities Together.`,
  price: "30GBP",
  edition: "20/20",
  size: "10cm x 20cm"
};
//<Print name="nhs" printImage={lgbt_orange} print={nhsPrint}/>


function Charity() {
  return (
    <div>
      <div style={divStyle}>
        <h2>Charity Print Sale</h2>

        <p>I've produced a series of designs of linocut prints which I am selling to raise money for a few charities which have been in my thoughts during lockdown.</p>
        
        <p>All proceeds from this print sale will be donated to the organisations specified, and it's open to UK only for now as I'm taking care of postage.</p>
        <p>Send your donation to me via this PayPay link and add a note with which artwork you'd like, with your name and address so I know where to send the print!</p>
        <p><a href="https://paypal.me/murphyartist">https://paypal.me/murphyartist</a></p>
        <p>Please direct any questions through my CONTACT page and thank you for helping some good causes!</p> 
        <div class="flexbox-container">
        <Print name="Still Together" printImage={lgbt_10} print={lgbtPrint} />
      </div>
      </div>
     </div>
  );
}

export default Charity;
