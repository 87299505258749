import React from 'react';
import Images from './images.js';
import Bio from './Bio.js'
import Contact from './Contact.js'
import ThreeD from './ThreeD.js'
import Shop from './Shop.js';
import './buttons.css';
import Tween from 'rc-tween-one';
import BezierPlugin from 'rc-tween-one/lib/plugin/BezierPlugin';
import TopArrow from './TopArrow.js';
import FunContent from './FunContent.js';
import {FaInstagram, FaCartPlus, FaGamepad} from 'react-icons/fa';
import ContentButton from './ContentButton.js';
import Charity from './Charity.js';

var listOfNudes = [];
var listOfPortraits = [];
var listOfOtherArt = [];
var listOfMain = [];

Tween.plugins.push(BezierPlugin);

class StatefulContentWindow extends React.Component {
  importAll(r) {
    return r.keys().map(r);
  }
  componentWillMount() {
    listOfNudes = this.importAll(require.context('./images/nudes/', false, /\.(png|JPG|jpe?g|svg)$/));
    listOfPortraits = this.importAll(require.context('./images/portraits/', false, /\.(png|JPG|jpe?g|svg)$/));
    listOfOtherArt = this.importAll(require.context('./images/other/', false, /\.(png|JPG|jpe?g|svg)$/));
    listOfMain = this.importAll(require.context('./images/main/', false, /\.(png|JPG|jpe?g|svg)$/));
  }

  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.state = { activeContent: "splash" };
  }

  handleButtonClick(name) {
    this.setState({ activeContent: name });
  }

  render() {
    let content;

    let bioButton = <ContentButton type="regularButton" label="bio" callback={this.handleButtonClick} />;
    let threeDButton = <ContentButton type="regularButton" label="3d" callback={this.handleButtonClick} />;
    let nudeButton = <ContentButton type="regularButton" label="male form" callback={this.handleButtonClick} />;
    let portraitsButton = <ContentButton type="regularButton" label="portraits" callback={this.handleButtonClick} />;
    let contactButton = <ContentButton type="regularButton" label="contact" callback={this.handleButtonClick}/>;
    let charityButton = <ContentButton type="regularButton" label="charity" callback={this.handleButtonClick} />;

    let shopButton = <a href="https://www.artfinder.com/cjmurphyart">
      <div class="button green" id="linkButton">
        <div id="dub-arrow">
          <FaCartPlus fill="#000" />
        </div>
        <a class="blackText">shop</a>
      </div>
    </a>;

    let igButton = <a href="https://www.instagram.com/cjm.art">
      <div class="button green" id="linkButton">
        <div id="dub-arrow">
          <FaInstagram fill="#000"/>
        </div>
        <a class="blackText">instagram</a>
      </div>
    </a>;
    
    let itchButton = <a href="https://murphytechart.itch.io/murphy-gallery">
      <div class="button orange" id="linkButton">
        <div id="dub-arrow">
          <FaGamepad fill="#000"/>
        </div>
        <a class="blackText">exhibition</a>
      </div>
    </a>;

    const activeContent = this.state.activeContent;

    switch (activeContent) {
      case "male form":
        content = <Images listOfImages={listOfNudes} key="nudes" />;
        break;
      case "portraits":
        content = <Images listOfImages={listOfPortraits} key="portraits" />;
        break;
      case "otherArt":
        content = <Images listOfImages={listOfOtherArt} key="otherArt" />;
        break;
      case "bio":
        content = <Bio />;
        break;
      case "contact":
        content = <Contact />;
        break;
      case "charity":
        content = <Charity />;
        break;
      case "shop":
        content = <Shop />;
        break;
      case "3d":
        content = <ThreeD />;
        break;
      case "splash":
      default:
        content = <div style={{ float: 'right', marginLeft: '30px', marginRight: '30px' }}>
          <p>14 Dec 2020: I'm launching a charity print sale to raise money for some good causes, click the button above!!</p>
          <p>1 Dec 2020: Visit the online experience I have created to take you closer to my work! <a href="https://murphytechart.itch.io/murphy-gallery">Click here.</a></p>
          <FunContent />
          <Images listOfImages={listOfMain} key="main" />
        </div>;
    }
    let topButton = <TopArrow />;

    return (<div>
      {nudeButton}
      {portraitsButton}
      {bioButton}
      {contactButton}
      {charityButton}
      {shopButton}
      {igButton}
      {itchButton}
      {content}
      {topButton}
    </div>
    );
  }
}

export default StatefulContentWindow;