import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { motion } from "framer-motion";

var listOfImages = [];

class Images extends React.Component {

  constructor(props) {
    super();
    listOfImages = props.listOfImages;
    this.incrementCounter = this.incrementCounter.bind(this);
    this.imageClicked = this.imageClicked.bind(this);
    this.state = { count: 12, total: listOfImages.length, view: "all" }
  }

  incrementCounter() {
    this.setState({ count: this.state.count + 12 })
  }
  imageClicked(image) {
    this.setState({ view: image })
  }

  render() {
    let imagePanel;
    let seeMore;
    const currentView = this.state.view;

    if (currentView == "all") {
      const currentCount = this.state.count;
      imagePanel = listOfImages.filter((image, index) => index < currentCount)
        .map((image, index) =>
          <a onClick={() => this.imageClicked(image)}><img key={index} src={image} loading="lazy" alt="info" class="painting"></img></a>
        )
      if (currentCount < this.state.total) {
        seeMore = <div><a onClick={this.incrementCounter}><h1>See more...</h1></a></div>
      }
    } else {
      imagePanel = <a onClick={() => this.imageClicked("all")}><img src={currentView} loading="lazy" alt="info" class="paintingEnlarged"></img></a>
    }


    return (
      <div class="images">
        {imagePanel}
        {seeMore}
      </div>

    )
  }
}
export default Images;


// const Box = posed.div({
//   enter: {
//     y: 0,
//     opacity: 1,
//     delay: 300,
//     transition: {
//       y: { type: 'spring', stiffness: 1000, damping: 15 },
//       default: { duration: 300 }
//     }
//   },
//   exit: {
//     y: 50,
//     opacity: 0,
//     transition: { duration: 150 }
//   }
// });